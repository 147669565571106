<template>
	<v-dialog v-model="dialog" max-width="500px" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark class="my-1 text-none" v-bind="attrs" v-on="on" rounded>
				<v-icon left> mdi-plus </v-icon>
				Nuevo PEP
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-body-1 primary--text">Nuevo PEP</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<validation-alert :validationErrors="validationErrors" />
					<v-form ref="form" v-model="isValidFormDialog" lazy-validation>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.code"
									:rules="codeRules"
									label="PEP"
									required
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.pep_description"
									label="Descripción"
									required
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsible_id"
									:items="users"
									:rules="responsibleRules"
									item-text="fullName"
									item-value="id"
									label="Responsable"
									required
									@keyup="
										(v) => {
											listenSearch(v.target.value);
										}
									"
									:filter="customFilter"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="close()"> Cancelar </v-btn>
				<v-btn color="blue darken-1" :loading="isLoading" :disabled="isLoading" @click="save()" text>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { $EventBus } from '@/main';
export default {
	name: 'CreateManagementDialog',
	props: {},

	data: () => ({
		timeout: null,
		previousSearchValue: '',
		dialog: false,
		isLoading: false,
		isValidFormDialog: false,
		codeRules: [(v) => !!v || 'Campo requerido'],
		responsibleRules: [(v) => !!v || 'Campo requerido'],
		editedItem: {
			code: '',
		},
		defaultItem: {
			code: '',
			pep_description: '',
			responsible_id: '',
		},
	}),

	computed: {
		validationErrors() {
			return this.$store.getters['peps/getValidationErrors'];
		},
		users() {
			return this.$store.getters['users/getUsers'];
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
			if (val) {
				this.$store.dispatch('users/getUsers', { active: 1 });
			}
		},
	},

	methods: {
		listenSearch(value) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				const searchValue = value || '';
				const trimmedSearchValue = searchValue.trim();

				if (searchValue === '') {
					this.$store.dispatch('users/searchGetUsers', {
						search: '',
						active: true,
						searchType: 'select',
						pagination: false,
					});

					this.previousSearchValue = '';
					return;
				}

				if (trimmedSearchValue === '') {
					return;
				}

				if (this.previousSearchValue !== trimmedSearchValue) {
					this.$store.dispatch('users/searchGetUsers', {
						search: trimmedSearchValue,
						active: true,
						searchType: 'select',
						pagination: false,
					});

					this.previousSearchValue = trimmedSearchValue;
				}
			}, 600);
		},

		customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every((term) => fullName.includes(term));
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = { ...this.defaultItem };
				this.$store.dispatch('peps/cleanValidationErrors');
				this.$refs.form.resetValidation();
			});
		},

		showSnack(color = 'green', text = '') {
			$EventBus.$emit('showSnack', { color, text });
		},

		async save() {
			if (this.$refs.form.validate()) {
				this.isLoading = true;
				try {
					await this.$store.dispatch('peps/savePep', this.editedItem);
					this.showSnack('green', 'Se ha registrado satisfactoriamente');
					this.$emit('resetFilter');
					this.close();
				} catch (error) {}
				this.isLoading = false;
			}
		},
	},
	components: {
		ValidationAlert,
	},
};
</script>
